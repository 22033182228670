<div class="full-height picture" [@fadeIn]="'in'">
  <div class="text">
    <h1>Bedankt voor je registratie!</h1>
  </div>
  <app-scrolldown></app-scrolldown>
</div>

<div class="vlekken-op-achtergrond">
  <div class="page-wrapper">
    <div class="intro-text">
      <h1>Hey {{ namen }}!</h1>
      <p>Deze overzichtspagina blijft voor <span *ngIf="enkel">jou</span><span *ngIf="!enkel">jullie</span> beschikbaar.
      </p>
      <p>Eventuele wijzigingen zullen ook hier worden vermeld indien nodig.</p>
      <p>Wil je je registratie toch nog aanpassen? Stuur ons dan een mailtje via <a
        href="mailto:wijtrouwen@rensenlaure.be?SUBJECT=Aanpassing registratie van {{namen}}">wijtrouwen@rensenlaure.be</a>
        zodat wij de nodige aanpassingen kunnen doen.</p>
    </div>

    <div *ngIf="!enkelAvond">
      <div class="tijdlijn-container-dag hide-small-display">
        <div class="tijdlijn-item flex-20">
          <div class="icon-container">
            <div class="icon icon-1"></div>
          </div>
          <div class="uur">
            <span class="titel">FIRST LOOK</span>
            <span class="tijdstip">10u00</span>
          </div>
          <div class="horizontale-lijn"></div>
          <div class="verticale-lijn"></div>
          <div class="locatie">
            <span class="titel">Ten Aard</span>
            <span class="adres">Beekhoek 50, Geel</span>
          </div>
        </div>
        <div class="tijdlijn-item flex-20">
          <div class="icon-container">
            <div class="icon icon-2"></div>
          </div>
          <div class="uur">
            <span class="titel">JA-WOORD</span>
            <span class="tijdstip">11u30</span>
          </div>
          <div class="horizontale-lijn"></div>
          <div class="verticale-lijn"></div>
          <div class="locatie">
            <span class="titel">Gemeentehuis Olen</span>
            <span class="adres">Dorp 1, Olen</span>
          </div>
        </div>
        <div class="tijdlijn-item flex-20">
          <div class="icon-container">
            <div class="icon icon-3"></div>
          </div>
          <div class="uur">
            <span class="titel">RECEPTIE</span>
            <span class="tijdstip">13u00</span>
          </div>
          <div class="horizontale-lijn"></div>
          <div class="verticale-lijn"></div>
          <div class="locatie">
            <span class="titel">Ten Aard</span>
            <span class="adres">Beekhoek 50, Geel</span>
          </div>
        </div>
        <div class="tijdlijn-item flex-40">
          <div class="combined">
            <div class="icon-container">
              <div class="icon icon-4"></div>
            </div>
            <div class="icon-container">
              <div class="icon icon-5"></div>
            </div>
          </div>
          <div class="combined">
            <div class="uur">
              <span class="titel">DINER</span>
              <span class="tijdstip">17u00</span>
            </div>
            <div class="uur">
              <span class="titel">DANS & DESSERT</span>
              <span class="tijdstip">20u00</span>
            </div>
          </div>
          <div class="horizontale-lijn"></div>
          <div class="verticale-lijn"></div>
          <div class="locatie">
            <span class="titel">Hooge Heyde</span>
            <span class="adres">Steenfortstraat 5, Kasterlee</span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="enkelAvond">
      <div class="tijdlijn-container-avond hide-small-display">
        <div class="tijdlijn-item">
          <div class="icon-container">
            <div class="icon icon-2"></div>
          </div>
          <div class="uur">
            <span class="titel">JA-WOORD</span>
            <span class="tijdstip">11u30</span>
          </div>
          <div class="horizontale-lijn"></div>
          <div class="verticale-lijn"></div>
          <div class="locatie">
            <span class="titel">Gemeentehuis Olen</span>
            <span class="adres">Dorp 1, Olen</span>
          </div>
        </div>
        <div class="tijdlijn-item">
          <div class="icon-container">
            <div class="icon icon-5"></div>
          </div>
          <div class="uur">
            <span class="titel">DANS & DESSERT</span>
            <span class="tijdstip">20u00</span>
          </div>
          <div class="horizontale-lijn"></div>
          <div class="verticale-lijn"></div>
          <div class="locatie">
            <span class="titel">Hooge Heyde</span>
            <span class="adres">Steenfortstraat 5, Kasterlee</span>
          </div>
        </div>
      </div>
    </div>

    <div class="hide-large-display">
      <div class="planning-items">
        <div *ngIf="!enkelAvond">
          <app-locatie-icon
            [adres]="'Beekhoek 50, Geel'"
            [icon]="'1-firstlook.png'"
            [locatie]="'Ten Aard'"
            [titel]="'FIRST LOOK'"
            [uur]="'10u00'">
          </app-locatie-icon>
        </div>
        <div>
          <app-locatie-icon
            [adres]="'Dorp 1, Olen'"
            [icon]="'2-jawoord.png'"
            [locatie]="'Gemeentehuis Olen'"
            [titel]="'JA-WOORD'"
            [uur]="'11u30'">
          </app-locatie-icon>
        </div>
        <div *ngIf="!enkelAvond">
          <app-locatie-icon
            [adres]="'Beekhoek 50, Geel'"
            [icon]="'3-receptie.png'"
            [locatie]="'Ten Aard'"
            [titel]="'RECEPTIE'"
            [uur]="'13u00'">
          </app-locatie-icon>
        </div>
        <div *ngIf="!enkelAvond">
          <app-locatie-icon
            [adres]="'Steenfortstraat 5, Kasterlee'"
            [icon]="'4-diner.png'"
            [locatie]="'Hooge Heyde'"
            [titel]="'DINER'"
            [uur]="'17u00'">
          </app-locatie-icon>
        </div>
        <div>
          <app-locatie-icon
            [adres]="'Steenfortstraat 5, Kasterlee'"
            [icon]="'5-dessert.png'"
            [locatie]="'Hooge Heyde'"
            [titel]="'Dans & Dessert'"
            [uur]="'20u00'">
          </app-locatie-icon>
        </div>
      </div>
    </div>

    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>

    <div class="image-wrapper">
      <img class="foto-verloofd right" src="assets/images/logo_transparant.png" alt="verloving"/>

      <div class="text">
        <h3>CORONA UPDATE</h3>
        <ul>
          <li>Het huwelijk en het feest gaan gewoon door zoals gepland.</li>
          <li>Huwelijksfeesten volgen de regels van de horeca. Dansfeesten zijn dus nog verboden, het feest zal zittend
            doorgaan.</li>
          <li>Het dragen van een mondmasker is verplicht tijdens verplaatsingen.</li>
          <li>Het aantal toegelaten personen in het gemeentehuis is zeer beperkt. Alle personen die met ons mee naar
            binnen mogen, werden door ons reeds persoonlijk op de hoogte gebracht. Buiten op het pleintje voor het
            gemeentehuis is iedereen welkom!</li>
          <li>Een overnachting boeken kan enkel telefonisch (014 55 18 34) of via email
            <a href="mailto:info.hoogeheyde@corsendonkhotels.com">info.hoogeheyde@corsendonkhotels.com</a>. Vermeld
            zeker "Trouwfeest Rens & Laure" tijdens je boeking. Op booking websites zal het hotel altijd volzet
            aangeven.</li>
        </ul>
      </div>

      <div class="clear"></div>
    </div>

    <br/>

    <div class="image-wrapper">
      <img class="foto-verloofd left" src="assets/images/Laure%20&%20Rens-61.jpg" alt="verloving"/>

      <div class="text">
        <h3>HOE HET BEGON...</h3>
        <p>In 2015 waagden we ons allebei op onbekend terrein, want hoewel Rens al in de IT-sector werkte, bleek ook
          online daten toch ook voor hem nog nieuw te zijn.</p>

        <br/>

        <p>Laure nam initiatief en verstuurde het allereerste bericht naar Rens.
          En na een maand wachten werd een dagelijkse mail naar elkaar onze grootste wens.
          We leerden elkaar dus kennen met massa’s geschreven woorden.
          Het was dan ook erg gek toen we plots ook echt elkaars stem hoorden.</p>

        <br/>
      </div>

      <div class="clear"></div>
    </div>

    <br/>

    <div class="image-wrapper">
      <img class="foto-verloofd right" src="assets/images/20191031_185240.jpg" alt="verloving"/>

      <div class="text">
        <p>Ondertussen wonen we al een aantal jaar samen, in onze eigen warme stek.
          Het huwelijksaanzoek kwam er in Zeeland, op een voor ons belangrijke en rustgevende plek.
          Allebei zijn we klaar voor een volgende stap in het leven.
          Klaar om er ook als man en vrouw voor elkaar te zijn en om ons ja-woord aan elkaar te geven!
        </p>
      </div>

      <div class="clear">
      </div>
    </div>

    <br/>

    <div class="image-wrapper">
      <img class="foto-verloofd left" src="assets/images/Laure%20&%20Rens-108-cropped.jpg" alt="verloving"/>

      <div class="text">
        <h3>#UNPLUGGED WEDDING</h3>
        <p>Ons liefdesverhaal begon online. Een echte ontmoeting kwam pas later.
          Laat het ons dit keer dus maar eens omdraaien...</p>

        <br/>

        <p>Bij ons huwelijk ligt de focus op de verbondenheid met elkaar en alle mensen die ons lief hebben. We houden
          het graag puur en intiem en doen het graag met wat minder technologie. Echte ontmoetingen en echte emoties,
          dat is waar het voor ons gedurende die dag om draait.</p>

        <br/>

        <p>We zouden het daarom écht bijzonder fijn vinden <span *ngIf="enkel">mocht je</span><span *ngIf="!enkel">mochten jullie</span>
          je smartphones zoveel mogelijk in je binnenzak willen houden.
          Onze enthousiaste huwelijksfotografe zal haar uiterste best doen om alle herinneringen voor ons op foto vast
          te leggen. Wij zien daarop uiteraard liever jullie glimlach dan <span *ngIf="enkel">jouw toestel</span><span
            *ngIf="!enkel">jullie toestellen</span>.
        </p>

        <br/>

        <p><span *ngIf="enkel">Gun je</span><span *ngIf="!enkel">Gunnen jullie</span> ons na afloop ook de primeur om
          van onze hoogdag iets op sociale media te plaatsen a.u.b.? Het zou voor ons echt veel betekenen. </p>
      </div>

      <div class="clear">
      </div>
    </div>

    <br/>

    <div class="image-wrapper">
      <img class="foto-verloofd left" src="assets/images/Laure%20&%20Rens-43.jpg" alt="verloving"/>

      <div class="text">

        <h3>#KIDSFREEPARTY</h3>
        <p>’t Is niet dat we geen fan van kinderen zijn.<br/>
          Maar voor ons feest ’s avonds vinden we ze nog iets te klein.
        </p>

        <br/>

        <h3>#WATMETCORONA?</h3>
        <p>Wij laten het coronavirus niet tussen ons komen en zullen ons huwelijk sowieso laten doorgaan. We zullen de
          organisatie van onze dag aanpassen aan de op dat moment geldende maatregelen.</p>
      </div>


      <div class="clear">
      </div>
    </div>

    <div class="item-wrapper">
      <div class="foto">
        <img src="assets/images/Laure%20&%20Rens-109.jpg" alt="verloving"/>

        <div class="text">
          <h1>Wij kijken er alvast naar uit! <br/>Graag tot dan!</h1>
        </div>
      </div>


      <div class="clear">
      </div>
    </div>
  </div>
</div>
