<div class="container vlekken-op-achtergrond">
  <div></div>
  <div class="center">
    <div class="header">
      <button class="button-small no-select" routerLink="/admin/invitees/overzicht">Terug</button>
      <button class="button-small no-select" routerLink="/logout">Afmelden</button>
    </div>

    <h1>Nieuwe Genodigden</h1>

    <div>
      <form (ngSubmit)="onSubmit()" [formGroup]="nieuwInviteeForm">
        <div class="persoon-scheiding">
          <div>
            <h3>Persoon 1</h3>
            <div>
              <input formControlName="persoon1Voornaam" placeholder="Voornaam" type="text"/>
            </div>
            <div>
              <input formControlName="persoon1Achternaam" placeholder="Achternaam" type="text"/>
            </div>
          </div>

          <div>
            <h3>Persoon 2</h3>
            <div>
              <input formControlName="persoon2Voornaam" placeholder="Voornaam" type="text"/>
            </div>
            <div>
              <input formControlName="persoon2Achternaam" placeholder="Achternaam" type="text"/>
            </div>
          </div>
        </div>

        <div style="height: 20px;"></div>

        <div>
          <input formControlName="code" placeholder="Code" type="text"/>
        </div>

        <div style="height: 20px;"></div>

        <div>
          <input formControlName="type" id="dag" name="type" type="radio" value="DAG"/>
          <label for="dag"> Dag</label><br>
          <input formControlName="type" id="avond" name="type" type="radio" value="AVOND"/>
          <label for="avond"> Avond</label><br>
        </div>

        <div style="height: 20px;"></div>

        <button class="no-select">Opslaan</button>
      </form>
    </div>
  </div>
  <div></div>
</div>
