<div class="vlekken-op-achtergrond">
  <div class="page-wrapper">
    <form (ngSubmit)="onSubmit()" [formGroup]="registrationForm">
      <div class="titel">
        <h1>Registratie</h1>
        <p>Klik op de vakjes van welke momenten <span *ngIf="enkel">je</span><span *ngIf="!enkel">jullie</span> aanwezig
          <span
            *ngIf="enkel">zal</span><span *ngIf="!enkel">zullen</span> zijn.</p>
      </div>

      <div class="gap"></div>

      <div class="table">
        <div class="row hide-small-display">
          <div class="first"></div>
          <div class="naam">{{voornaam1}}</div>
          <div class="naam" *ngIf="!enkel">{{voornaam2}}</div>
        </div>
        <div class="row" *ngIf="!enkelAvond">
          <div class="first">
            <app-locatie-icon
              [adres]="'Beekhoek 50, Geel'"
              [icon]="'1-firstlook.png'"
              [locatie]="'Ten Aard'"
              [titel]="'FIRST LOOK'"
              [uur]="'10u00'">
            </app-locatie-icon>
          </div>
          <div class="hide-small-display">
            <div class="pretty p-svg p-curve p-toggle">
              <input type="checkbox" value="true" formControlName="firstLook1"/>
              <div class="state p-success p-on">
                <label>Aanwezig</label>
              </div>
              <div class="state p-off">
                <label>Aanwezig?</label>
              </div>
            </div>
          </div>
          <div *ngIf="!enkel" class="hide-small-display">
            <div class="pretty p-svg p-curve p-toggle">
              <input type="checkbox" value="true" formControlName="firstLook2"/>
              <div class="state p-success p-on">
                <label>Aanwezig</label>
              </div>
              <div class="state p-off">
                <label>Aanwezig?</label>
              </div>
            </div>
          </div>

          <div class="checkboxes hide-large-display">
            <div class="{{enkel ? 'enkel' : ''}}">
              <div class="naam">{{voornaam1}}</div>
              <div class="pretty p-svg p-curve p-toggle">
                <input type="checkbox" value="true" formControlName="firstLook1"/>
                <div class="state p-success p-on">
                  <label>Aanwezig</label>
                </div>
                <div class="state p-off">
                  <label>Aanwezig?</label>
                </div>
              </div>
            </div>
            <div *ngIf="!enkel">
              <div class="naam">{{voornaam2}}</div>
              <div class="pretty p-svg p-curve p-toggle">
                <input type="checkbox" value="true" formControlName="firstLook2"/>
                <div class="state p-success p-on">
                  <label>Aanwezig</label>
                </div>
                <div class="state p-off">
                  <label>Aanwezig?</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="first">
            <app-locatie-icon
              [adres]="'Dorp 1, Olen'"
              [icon]="'2-jawoord.png'"
              [locatie]="'Gemeentehuis Olen'"
              [titel]="'JA-WOORD'"
              [uur]="'11u30'">
            </app-locatie-icon>
          </div>
          <div class="hide-small-display">
            <div class="pretty p-svg p-curve p-toggle">
              <input type="checkbox" value="true" formControlName="jaWoord1"/>
              <div class="state p-success p-on">
                <label>Aanwezig</label>
              </div>
              <div class="state p-off">
                <label>Aanwezig?</label>
              </div>
            </div>
          </div>
          <div *ngIf="!enkel" class="hide-small-display">
            <div class="pretty p-svg p-curve p-toggle">
              <input type="checkbox" value="true" formControlName="jaWoord2"/>
              <div class="state p-success p-on">
                <label>Aanwezig</label>
              </div>
              <div class="state p-off">
                <label>Aanwezig?</label>
              </div>
            </div>
          </div>

          <div class="checkboxes hide-large-display">
            <div class="{{enkel ? 'enkel' : ''}}">
              <div class="naam">{{voornaam1}}</div>
              <div class="pretty p-svg p-curve p-toggle">
                <input type="checkbox" value="true" formControlName="jaWoord1"/>
                <div class="state p-success p-on">
                  <label>Aanwezig</label>
                </div>
                <div class="state p-off">
                  <label>Aanwezig?</label>
                </div>
              </div>
            </div>
            <div *ngIf="!enkel">
              <div class="naam">{{voornaam2}}</div>
              <div class="pretty p-svg p-curve p-toggle">
                <input type="checkbox" value="true" formControlName="jaWoord2"/>
                <div class="state p-success p-on">
                  <label>Aanwezig</label>
                </div>
                <div class="state p-off">
                  <label>Aanwezig?</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!enkelAvond">
          <div class="first">
            <app-locatie-icon
              [adres]="'Beekhoek 50, Geel'"
              [icon]="'3-receptie.png'"
              [locatie]="'Ten Aard'"
              [titel]="'RECEPTIE'"
              [uur]="'13u00'">
            </app-locatie-icon>
          </div>
          <div class="hide-small-display">
            <div class="pretty p-svg p-curve p-toggle">
              <input type="checkbox" value="true" formControlName="receptie1"/>
              <div class="state p-success p-on">
                <label>Aanwezig</label>
              </div>
              <div class="state p-off">
                <label>Aanwezig?</label>
              </div>
            </div>
          </div>
          <div *ngIf="!enkel" class="hide-small-display">
            <div class="pretty p-svg p-curve p-toggle">
              <input type="checkbox" value="true" formControlName="receptie2"/>
              <div class="state p-success p-on">
                <label>Aanwezig</label>
              </div>
              <div class="state p-off">
                <label>Aanwezig?</label>
              </div>
            </div>
          </div>

          <div class="checkboxes hide-large-display">
            <div class="{{enkel ? 'enkel' : ''}}">
              <div class="naam">{{voornaam1}}</div>
              <div class="pretty p-svg p-curve p-toggle">
                <input type="checkbox" value="true" formControlName="receptie1"/>
                <div class="state p-success p-on">
                  <label>Aanwezig</label>
                </div>
                <div class="state p-off">
                  <label>Aanwezig?</label>
                </div>
              </div>
            </div>
            <div *ngIf="!enkel">
              <div class="naam">{{voornaam2}}</div>
              <div class="pretty p-svg p-curve p-toggle">
                <input type="checkbox" value="true" formControlName="receptie2"/>
                <div class="state p-success p-on">
                  <label>Aanwezig</label>
                </div>
                <div class="state p-off">
                  <label>Aanwezig?</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!enkelAvond">
          <div class="first">
            <app-locatie-icon
              [adres]="'Steenfortstraat 5, Kasterlee'"
              [icon]="'4-diner.png'"
              [locatie]="'Hooge Heyde'"
              [titel]="'DINER'"
              [uur]="'17u00'">
            </app-locatie-icon>
          </div>
          <div class="hide-small-display">
            <div class="pretty p-svg p-curve p-toggle">
              <input type="checkbox" value="true" formControlName="diner1"/>
              <div class="state p-success p-on">
                <label>Aanwezig</label>
              </div>
              <div class="state p-off">
                <label>Aanwezig?</label>
              </div>
            </div>
          </div>
          <div *ngIf="!enkel" class="hide-small-display">
            <div class="pretty p-svg p-curve p-toggle">
              <input type="checkbox" value="true" formControlName="diner2"/>
              <div class="state p-success p-on">
                <label>Aanwezig</label>
              </div>
              <div class="state p-off">
                <label>Aanwezig?</label>
              </div>
            </div>
          </div>

          <div class="checkboxes hide-large-display">
            <div class="{{enkel ? 'enkel' : ''}}">
              <div class="naam">{{voornaam1}}</div>
              <div class="pretty p-svg p-curve p-toggle">
                <input type="checkbox" value="true" formControlName="diner1"/>
                <div class="state p-success p-on">
                  <label>Aanwezig</label>
                </div>
                <div class="state p-off">
                  <label>Aanwezig?</label>
                </div>
              </div>
            </div>
            <div *ngIf="!enkel">
              <div class="naam">{{voornaam2}}</div>
              <div class="pretty p-svg p-curve p-toggle">
                <input type="checkbox" value="true" formControlName="diner2"/>
                <div class="state p-success p-on">
                  <label>Aanwezig</label>
                </div>
                <div class="state p-off">
                  <label>Aanwezig?</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="first">
            <app-locatie-icon
              [adres]="'Steenfortstraat 5, Kasterlee'"
              [icon]="'5-dessert.png'"
              [locatie]="'Hooge Heyde'"
              [titel]="'Dans & Dessert'"
              [uur]="'20u00'">
            </app-locatie-icon>
          </div>
          <div class="hide-small-display">
            <div class="pretty p-svg p-curve p-toggle">
              <input type="checkbox" value="true" formControlName="dessert1"/>
              <div class="state p-success p-on">
                <label>Aanwezig</label>
              </div>
              <div class="state p-off">
                <label>Aanwezig?</label>
              </div>
            </div>
          </div>
          <div *ngIf="!enkel" class="hide-small-display">
            <div class="pretty p-svg p-curve p-toggle">
              <input type="checkbox" value="true" formControlName="dessert2"/>
              <div class="state p-success p-on">
                <label>Aanwezig</label>
              </div>
              <div class="state p-off">
                <label>Aanwezig?</label>
              </div>
            </div>
          </div>

          <div class="checkboxes hide-large-display">
            <div class="{{enkel ? 'enkel' : ''}}">
              <div class="naam">{{voornaam1}}</div>
              <div class="pretty p-svg p-curve p-toggle">
                <input type="checkbox" value="true" formControlName="dessert1"/>
                <div class="state p-success p-on">
                  <label>Aanwezig</label>
                </div>
                <div class="state p-off">
                  <label>Aanwezig?</label>
                </div>
              </div>
            </div>
            <div *ngIf="!enkel">
              <div class="naam">{{voornaam2}}</div>
              <div class="pretty p-svg p-curve p-toggle">
                <input type="checkbox" value="true" formControlName="dessert2"/>
                <div class="state p-success p-on">
                  <label>Aanwezig</label>
                </div>
                <div class="state p-off">
                  <label>Aanwezig?</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <textarea formControlName="opmerkingen" rows="5"
                  placeholder="Heb je eventuele vragen of opmerkingen?"></textarea>
      </div>

      <div class="gap"></div>

      <div>
        <input [ngClass]="{ 'is-invalid': submitted && registrationForm.controls.email.errors }"
               formControlName="email" type="email" placeholder="Geef je email adres om up-to-date te blijven"/>
      </div>

      <div class="gap"></div>

      <div>
        <app-alert></app-alert>
      </div>

      <div>
        <button>REGISTREER AANWEZIGHEID</button>
      </div>
    </form>
  </div>
</div>
