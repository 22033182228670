import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Statistics} from '../_models/statistics';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(
    private http: HttpClient
  ) {
  }

  getStatistics(): Observable<Statistics> {
    return this.http.get<Statistics>('https://www.rensenlaure.be/api/statistics.php');
  }

}
