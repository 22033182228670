import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Invitee} from '../../../_models/invitee';
import {InviteesService} from '../../../_services/invitees.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-detail',
  templateUrl: './invitee-detail.component.html',
  styleUrls: ['./invitee-detail.component.scss']
})
export class InviteeDetailComponent implements OnInit, OnDestroy {

  private paramSubscription: Subscription;
  public invitee: Invitee;
  private inviteeSubcribtion: Subscription;

  public namen: string;

  constructor(
    private route: ActivatedRoute,
    private inviteeService: InviteesService
  ) {
  }

  ngOnInit(): void {
    this.paramSubscription = this.route.params.subscribe(params => {
      const id = +params.id; // (+) converts string 'id' to a number

      this.inviteeSubcribtion = this.inviteeService.getInvitees()
        .subscribe(data => {
          this.invitee = data.filter(val => val.id === id)[0];
          console.log(this.invitee);

          this.namen = this.invitee.voornaam1 + ' ' + this.invitee.achternaam1;

          if (this.invitee.voornaam2 !== '') {
            this.namen += ' & ' + this.invitee.voornaam2 + ' ' + this.invitee.achternaam2;
          }
        });
    });
  }

  ngOnDestroy(): void {
    if (this.inviteeSubcribtion !== undefined) {
      this.inviteeSubcribtion.unsubscribe();
    }
    this.paramSubscription.unsubscribe();
  }

}
