import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../_services/alert.service';
import {AuthenticationService} from '../_services/authentication.service';
import {first} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginForm: FormGroup;
  public loading: boolean;
  public submitted: boolean;
  private subscription: Subscription;
  private returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      code: ['', Validators.required]
    });

    this.loading = false;
    this.submitted = false;
    this.returnUrl = '/';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSubmit(): void {
    this.submitted = true;

    // clear alerts
    this.alertService.clear();

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.subscription = this.authenticationService.login(this.loginForm.controls.code.value)
      .pipe(first())
      .subscribe(
        data => {
          if (!data.succes) {
            this.showError(data);
            return;
          }

          if (data.getuige) {
            this.returnUrl = '/vraag';
          } else if (data.isAdmin) {
            this.returnUrl = '/admin';
          } else if (data.user.laatsteWijziging !== null) {
            this.returnUrl = '/planning';
          }

          this.alertService.clear();
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.showError(error);
        }
      );
  }

  showError(data: any): void {
    this.alertService.error('Foute code opgegeven');
    this.loading = false;
  }

}
