import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-locatie-icon',
  templateUrl: './locatie-icon.component.html',
  styleUrls: ['./locatie-icon.component.scss']
})
export class LocatieIconComponent implements OnInit {

  @Input()
  icon: string;

  @Input()
  titel: string;

  @Input()
  uur: string;

  @Input()
  locatie: string;

  @Input()
  adres: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
