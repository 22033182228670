<div class="wrapper">
  <div class="icon-container">
    <div class="icon" style="background-image: url('../../../assets/images/{{icon}}');"></div>
  </div>

  <div class="info hide-large-display" *ngIf="titel">
    <div class="uur">
      <span class="titel">{{titel}}</span>
      <span class="tijdstip">{{uur}}</span>
    </div>
  </div>

  <div class="lijn-1" *ngIf="titel"></div>
  <div class="lijn-2" *ngIf="titel"></div>

  <div class="info" *ngIf="titel">
    <div class="uur hide-small-display">
      <span class="titel">{{titel}}</span>
      <span class="tijdstip">{{uur}}</span>
    </div>

    <div class="locatie">
      <span class="titel">{{locatie}}</span>
      <span class="adres">{{adres}}</span>
    </div>
  </div>
</div>
