import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Invitee} from '../_models/invitee';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InviteesService {

  constructor(
    private http: HttpClient
  ) {
  }

  getInvitees(): Observable<Invitee[]> {
    return this.http.get<Invitee[]>('https://www.rensenlaure.be/api/invitees.php');
  }

  createInvitee(invitee: Invitee): Observable<Invitee> {
    return this.http.post<Invitee>('https://www.rensenlaure.be/api/invitees.php', invitee);
  }

}
