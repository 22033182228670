import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import {AlertComponent} from './_components/alert/alert.component';
import {GetuigeComponent} from './getuige/getuige.component';
import {LogoutComponent} from './logout/logout.component';
import {InviteeNieuwComponent} from './admin/invitees/nieuw/nieuw.component';
import {InviteeOverzichtComponent} from './admin/invitees/overzicht/overzicht.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ScrolldownComponent} from './_components/scrolldown/scrolldown.component';
import {ButtonComponent} from './_components/button/button.component';
import {UnpluggedComponent} from './unplugged/unplugged.component';
import {PlanningComponent} from './planning/planning.component';
import {RegistratieComponent} from './registratie/registratie.component';
import {LocatieIconComponent} from './_components/locatie-icon/locatie-icon.component';
import { InviteeDetailComponent } from './admin/invitees/detail/invitee-detail.component';
import { CoronaComponent } from './corona/corona.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    AlertComponent,
    GetuigeComponent,
    LogoutComponent,
    InviteeNieuwComponent,
    InviteeOverzichtComponent,
    ScrolldownComponent,
    ButtonComponent,
    UnpluggedComponent,
    PlanningComponent,
    RegistratieComponent,
    LocatieIconComponent,
    InviteeDetailComponent,
    CoronaComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
