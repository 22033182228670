<div class="full-height picture" [@fadeIn]="'in'">
  <div class="text-centered">
    <h1 class="middle">WIJ GAAN TROUWEN!</h1>

    <app-scrolldown></app-scrolldown>
  </div>
</div>

<div class="full-height vlekken-op-achtergrond">
  <div class="page-wrapper">
    <div class="text-centered">
      <h1>Welkom {{ namen }}!</h1>
    </div>
    <div class="text-centered">

      <p>Fijn dat <span *ngIf="enkel">je</span><span *ngIf="!enkel">jullie</span> de weg naar onze website gevonden
        <span *ngIf="enkel">hebt</span><span *ngIf="!enkel">hebben</span>.<br/>Ontdek hier hoe ons verhaal begon en kom
        meer
        te weten over de organisatie van onze huwelijksdag.</p>

      <p>Vergeet op het einde zeker <span *ngIf="enkel">je</span><span *ngIf="!enkel">jullie</span> aanwezigheid niet te
        registreren. </p>
    </div>

    <app-button [text]="enkel ? 'VERTEL ME MEER!' : 'VERTEL ONS MEER!'" [url]="'/unplugged'"></app-button>
  </div>
</div>
