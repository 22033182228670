import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-scrolldown',
  templateUrl: './scrolldown.component.html',
  styleUrls: ['./scrolldown.component.scss']
})
export class ScrolldownComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  scrollDown(): void {
    window.scroll({
      top: window.innerHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

}
