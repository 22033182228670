import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {Invitee} from 'src/app/_models/invitee';
import {AuthenticationService} from 'src/app/_services/authentication.service';
import {InviteesService} from 'src/app/_services/invitees.service';
import {Statistics} from '../../../_models/statistics';
import {StatisticsService} from '../../../_services/statistics.service';

@Component({
  selector: 'app-overzicht',
  templateUrl: './overzicht.component.html',
  styleUrls: ['./overzicht.component.scss']
})
export class InviteeOverzichtComponent implements OnInit, OnDestroy {

  public inviteeArray: Invitee[];
  private inviteeSubcribtion: Subscription;
  public statistics: Statistics;
  private statisticsSubscription: Subscription;
  private sortAscending = true;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private inviteeService: InviteesService,
    private statisticsService: StatisticsService
  ) {
  }

  ngOnInit(): void {
    this.inviteeSubcribtion = this.inviteeService.getInvitees()
      .pipe(first())
      .subscribe(data => {
        this.inviteeArray = data;
      });

    this.statisticsSubscription = this.statisticsService.getStatistics()
      .subscribe(data => {
        this.statistics = data;
        this.sort('namen');
      });
  }

  ngOnDestroy(): void {
    this.inviteeSubcribtion.unsubscribe();
    this.statisticsSubscription.unsubscribe();
  }

  sort(field: string): void {

    this.inviteeArray.sort((a, b) => {
      let fieldA = a[field];
      let fieldB = b[field];

      if (field === 'namen') {
        if (a.voornaam2 != null) {
          fieldA = a.voornaam1 + ' & ' + a.voornaam2;
        } else {
          fieldA = a.voornaam1 + ' ' + a.achternaam1;
        }

        if (b.voornaam2 != null) {
          fieldB = b.voornaam1 + ' & ' + b.voornaam2;
        } else {
          fieldB = b.voornaam1 + ' ' + b.achternaam1;
        }
      }

      if (fieldA == null) {
        fieldA = '';
      }
      if (fieldB == null) {
        fieldB = '';
      }

      let sortResult;

      if (this.sortAscending) {
        sortResult = fieldA.localeCompare(fieldB);
      } else {
        sortResult = fieldB.localeCompare(fieldA);
      }

      return sortResult;
    });

    this.sortAscending = !this.sortAscending;
  }

}
