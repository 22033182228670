export class Registration {
  code: string;
  firstLook1: boolean;
  firstLook2: boolean;
  jaWoord1: boolean;
  jaWoord2: boolean;
  receptie1: boolean;
  receptie2: boolean;
  diner1: boolean;
  diner2: boolean;
  dessert1: boolean;
  dessert2: boolean;
  opmerkingen: string;
  email: string;
}
