import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../_services/authentication.service';
import {Subscription} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(2000)
      ])
    ])
  ]
})
export class PlanningComponent implements OnInit, OnDestroy {

  enkel: boolean;
  enkelAvond: boolean;

  namen: string;

  currentUserSubscription: Subscription;

  constructor(
    private authService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.currentUserSubscription = this.authService.currentUser.subscribe(data => {
      this.enkelAvond = data.user.type === 'AVOND';

      this.namen = data.user.voornaam1;
      this.enkel = true;

      if (data.user.voornaam2 !== '') {
        this.enkel = false;
        this.namen += ' & ' + data.user.voornaam2;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.currentUserSubscription !== undefined) {
      this.currentUserSubscription.unsubscribe();
    }
  }

}
