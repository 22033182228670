import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../_services/authentication.service';
import {LoginResponse} from '../_models/loginresponse';
import {TokenTestService} from '../_services/token-test.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private tokenTestService: TokenTestService
  ) {
  }

  async testToken() {
    await this.tokenTestService.testToken().toPromise().catch(e => {});
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.testToken();

    const loginResponse = JSON.parse(localStorage.getItem('loginResponse')) as LoginResponse;

    if (loginResponse && loginResponse.succes) {
      if (route.data.getuigeVraag && loginResponse.getuige !== undefined) {
        return true;
      }

      if (loginResponse.getuige) {
        this.router.navigate(['/vraag']);
      }

      if (route.data.requireAdmin && loginResponse.isAdmin) {
        return true;
      } else if (!route.data.requireAdmin) {
        return true;
      }
    }

    this.authenticationService.logout();
    this.router.navigate(['/login']);
    return false;
  }
}
