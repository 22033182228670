import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../_services/authentication.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Registration} from '../_models/registration';
import {RegistrationService} from '../_services/registration.service';
import {Router} from '@angular/router';
import {AlertService} from '../_services/alert.service';

@Component({
  selector: 'app-registratie',
  templateUrl: './registratie.component.html',
  styleUrls: ['./registratie.component.scss']
})
export class RegistratieComponent implements OnInit, OnDestroy {

  submitted: boolean;
  enkel: boolean;
  enkelAvond: boolean;

  code: string;
  voornaam1: string;
  voornaam2: string;

  registrationForm: FormGroup;

  currentUserSubscription: Subscription;
  registrationSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private registrationService: RegistrationService,
    private alertService: AlertService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.registrationForm = this.formBuilder.group({
      firstLook1: [false],
      firstLook2: [false],
      jaWoord1: [false, Validators.required],
      jaWoord2: [false],
      receptie1: [false],
      receptie2: [false],
      diner1: [false],
      diner2: [false],
      dessert1: [false, Validators.required],
      dessert2: [false],
      opmerkingen: [''],
      email: ['', Validators.required]
    });

    this.currentUserSubscription = this.authService.currentUser.subscribe(data => {
      this.enkel = data.user.voornaam2 === '';
      this.enkelAvond = data.user.type === 'AVOND';

      this.code = data.user.code;
      this.voornaam1 = data.user.voornaam1;
      this.voornaam2 = data.user.voornaam2;
    });
  }

  ngOnDestroy(): void {
    if (this.currentUserSubscription !== undefined) {
      this.currentUserSubscription.unsubscribe();
    }
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.registrationForm.invalid) {
      this.alertService.error('Gelieve een email adres op te geven!');
      return;
    }

    const controls = this.registrationForm.controls;
    const registration: Registration = new Registration();

    registration.code = this.code;
    registration.dessert1 = controls.dessert1.value;
    registration.dessert2 = controls.dessert2.value;
    registration.diner1 = controls.diner1.value;
    registration.diner2 = controls.diner2.value;
    registration.firstLook1 = controls.firstLook1.value;
    registration.firstLook2 = controls.firstLook2.value;
    registration.jaWoord1 = controls.jaWoord1.value;
    registration.jaWoord2 = controls.jaWoord2.value;
    registration.receptie1 = controls.receptie1.value;
    registration.receptie2 = controls.receptie2.value;
    registration.opmerkingen = controls.opmerkingen.value;
    registration.email = controls.email.value;

    console.log(JSON.stringify(registration));

    this.registrationSubscription = this.registrationService.register(registration)
      .subscribe(ok => {
        this.router.navigate(['/planning']);
      }, error => {
        this.alertService.error('Er is iets fout gegaan: ' + error);
      });
  }

}
