import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {Invitee} from 'src/app/_models/invitee';
import {InviteesService} from 'src/app/_services/invitees.service';

@Component({
  selector: 'app-nieuw',
  templateUrl: './nieuw.component.html',
  styleUrls: ['./nieuw.component.scss']
})
export class InviteeNieuwComponent implements OnInit {

  public nieuwInviteeForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private inviteeService: InviteesService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.nieuwInviteeForm = this.formBuilder.group({
      persoon1Voornaam: ['', Validators.required],
      persoon1Achternaam: [''],
      persoon2Voornaam: [''],
      persoon2Achternaam: [''],
      code: ['', Validators.required],
      type: ['']
    });
  }

  onSubmit(): void {
    if (this.nieuwInviteeForm.invalid) {
      return;
    }

    const controls = this.nieuwInviteeForm.controls;

    const invitee = {
      voornaam1: controls.persoon1Voornaam.value,
      achternaam1: controls.persoon1Achternaam.value,
      voornaam2: controls.persoon2Voornaam.value,
      achternaam2: controls.persoon2Achternaam.value,
      code: controls.code.value,
      type: controls.type.value
    } as Invitee;

    this.inviteeService.createInvitee(invitee)
      .pipe(first())
      .subscribe(data => {
        this.router.navigate(['/admin/invitees/overzicht']);
      });
  }

}
