import {Component, OnDestroy, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AuthenticationService} from '../_services/authentication.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(2000)
      ])
    ])
  ]
})
export class HomeComponent implements OnInit, OnDestroy {

  namen: string;
  enkel: boolean;

  currentUserSubscription: Subscription;

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.currentUserSubscription = this.authService.currentUser.subscribe(data => {
      this.namen = data.user.voornaam1;
      this.enkel = true;

      if (data.user.voornaam2 !== '') {
        this.enkel = false;
        this.namen += ' & ' + data.user.voornaam2;
      }

      if (data.user.laatsteWijziging !== null) {
        this.router.navigate(['/planning']);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.currentUserSubscription !== undefined) {
      this.currentUserSubscription.unsubscribe();
    }
  }

}
