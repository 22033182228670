<div class="vlekken-op-achtergrond">
  <div class="page-wrapper">
    <div class="image-wrapper">
      <img class="foto-verloofd" src="assets/images/Laure%20&%20Rens-43.jpg" alt="verloving"/>

      <div class="text">

        <h3>#KIDSFREEPARTY</h3>
        <p>’t Is niet dat we geen fan van kinderen zijn.<br/>
          Maar voor ons feest ’s avonds vinden we ze nog iets te klein.
        </p>

        <br />

        <h3>#WATMETCORONA?</h3>
        <p>Wij laten het coronavirus niet tussen ons komen en zullen ons huwelijk sowieso laten doorgaan. We zullen de
          organisatie van onze dag aanpassen aan de op dat moment geldende maatregelen.</p>

        <br/>

        <p>Laat bij de registratie dus zeker je mailadres achter, zodat we <span *ngIf="enkel">jou</span><span
          *ngIf="!enkel">jullie</span> verder op de hoogte kunnen houden van eventuele wijzigingen. </p>
      </div>


      <div class="clear">
      </div>
    </div>

    <div [@fadeIn]="'in'" class="button-wrapper">
      <app-button [url]="'/registratie'"
                  [text]="'NAAR DE REGISTRATIE'"></app-button>
    </div>
  </div>
</div>
