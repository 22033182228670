<form (ngSubmit)="onSubmit()" [formGroup]="loginForm">

  <div class="root">
    <div class="column">
      <img class="flex-item logo" src="assets/images/logo.png">

      <div class="form-group flex-item">
        <input [ngClass]="{ 'is-invalid': submitted && loginForm.controls.code.errors }" formControlName="code" placeholder="Jouw unieke code"
               type="text"/>
      </div>

      <app-alert></app-alert>

      <div class="form-group flex-item">
        <button [disabled]="loading" class="no-select">
          Registreer je code
        </button>
      </div>
      <div class="form-group flex-item" style="text-align: center">
        <span style="width: 100%">Registreer je jouw code ook indien je niet aanwezig kan zijn?</span>
      </div>
    </div>
  </div>
</form>
