<div class="vlekken-op-achtergrond">
  <div class="page-wrapper">
    <div class="image-wrapper">
      <img class="foto-verloofd left" src="assets/images/Laure%20&%20Rens-61.jpg" alt="verloving"/>

      <div class="text">
        <h3>HOE HET BEGON...</h3>
        <p>In 2015 waagden we ons allebei op onbekend terrein, want hoewel Rens al in de IT-sector werkte, bleek
          online daten toch ook voor hem nog nieuw te zijn.</p>

        <br/>

        <p>Laure nam initiatief en verstuurde het allereerste bericht naar Rens.
          En na een maand op antwoord wachten werd een dagelijkse mail naar elkaar onze grootste wens.
          We leerden elkaar dus kennen met massa’s geschreven woorden.
          Het was dan ook erg gek toen we plots echt elkaars stem hoorden.</p>

        <br/>
      </div>

      <div class="clear"></div>
    </div>

    <br/>

    <div class="image-wrapper">
      <img class="foto-verloofd right" src="assets/images/20191031_185240.jpg" alt="verloving"/>

      <div class="text">
        <p>Ondertussen wonen we al een aantal jaren samen, in onze eigen warme stek.
          Het huwelijksaanzoek kwam er in Zeeland, op een voor ons belangrijke en rustgevende plek.
          Allebei zijn we klaar voor een volgende stap in het leven.
          Klaar om er ook als man en vrouw voor elkaar te zijn en om ons ja-woord aan elkaar te geven!
        </p>
      </div>

      <div class="clear">
      </div>
    </div>

    <br/>

    <div class="image-wrapper">
      <img class="foto-verloofd left" src="assets/images/Laure%20&%20Rens-108-cropped.jpg" alt="verloving"/>

      <div class="text">
        <h3>#UNPLUGGED WEDDING</h3>
        <p>Ons liefdesverhaal begon online. Een echte ontmoeting kwam pas later.
          Laat het ons dit keer dus maar eens omdraaien...</p>

        <br/>

        <p>Bij ons huwelijk ligt de focus op de verbondenheid met elkaar en alle mensen die ons lief hebben. We houden
          het graag puur en intiem en doen dat het liefst met wat minder technologie. Echte ontmoetingen en echte emoties,
          dat is waar het voor ons gedurende die dag om draait.</p>

        <br/>

        <p>We zouden het daarom écht bijzonder fijn vinden <span *ngIf="enkel">mocht je</span><span *ngIf="!enkel">mochten jullie</span>
          je smartphone zoveel mogelijk in je binnenzak willen houden.
          Onze enthousiaste huwelijksfotografe zal haar uiterste best doen om alle herinneringen voor ons op foto vast
          te leggen. Wij zien daarop uiteraard liever jullie glimlach dan <span *ngIf="enkel">jouw toestel</span><span
            *ngIf="!enkel">jullie toestellen</span>.
        </p>

        <br/>

        <p><span *ngIf="enkel">Gun je</span><span *ngIf="!enkel">Gunnen jullie</span> ons na afloop ook de primeur om
          van onze hoogdag zelf iets op sociale media te plaatsen a.u.b.? Dat zou voor ons echt veel betekenen. </p>
      </div>

      <div class="clear">
      </div>

      <div [@fadeIn]="'in'" class="button-wrapper">
        <app-button [url]="'/corona'"
                    [text]="enkel ? 'OK, DAT DOE IK!' : 'OK, DAT DOEN WE!'"></app-button>
      </div>
    </div>
  </div>
</div>
