<form (ngSubmit)="onSubmit()" [formGroup]="getuigeForm">
  <div class="root">
    <div class="vraag-deel een">
      <h1>Laadt de website correct?</h1>

      <div class="knoppen-rij">
        <button (click)="nee()" class="no-select">Nee</button>
        <button (click)="deelEenJa()" class="no-select">Ja</button>
      </div>
    </div>

    <div class="vraag-deel twee hidden">
      <h1>Is de styling OK?</h1>

      <div class="knoppen-rij">
        <button (click)="nee()" class="no-select">Nee</button>
        <button (click)="deelTweeJa()" class="no-select">Ja</button>
      </div>
    </div>

    <div class="vraag-deel drie hidden">
      <h1>{{ naam }},<br/><br/>Bedankt om dit te testen.<br/>Dat vind ik fijn.<br/><br/>Wil je op 17 juli 2021<br/>mijn
        getuige zijn?</h1>

      <div class="knoppen-rij">
        <button (click)="nee()" class="no-select">Nee</button>
        <button (click)="deelDrieJa()" class="no-select">Ja</button>
      </div>
    </div>

    <div class="vraag-deel vier hidden">
      <div style="width:100%;height:0;padding-bottom:83%;position:relative;">
        <iframe allowFullScreen class="giphy-embed" frameBorder="0"
                height="100%" src="https://giphy.com/embed/S9iMXx6Lqrr5mJoMxC" style="position:absolute" width="100%"></iframe>
      </div>

      <div class="knoppen-rij">
        <button (click)="afmelden()" class="no-select" id="afmelden">Afmelden</button>
      </div>
    </div>
  </div>
</form>
