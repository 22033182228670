import {Injectable} from '@angular/core';
import {Registration} from '../_models/registration';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(
    private http: HttpClient
  ) {
  }

  register(registration: Registration) {
    return this.http.post('https://www.rensenlaure.be/api/registration.php', registration);
  }
}
