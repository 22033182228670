<div class="container vlekken-op-achtergrond">
  <div></div>
  <div class="center">
    <div class="header">
      <button class="button-small no-select"># {{statistics.loginAttempts}}</button>
      <button class="button-small no-select" routerLink="/admin/invitees/nieuw">Nieuw</button>
      <button class="button-small no-select" routerLink="/logout">Afmelden</button>
    </div>

    <div class="statistics-wrapper">
      <div>
        <app-locatie-icon
          [icon]="'1-firstlook.png'">
        </app-locatie-icon>
        <span>{{statistics.geregistreerden.firstlook}}/{{statistics.genodigden.firstlook}}</span>
      </div>
      <div>
        <app-locatie-icon
          [icon]="'2-jawoord.png'">
        </app-locatie-icon>
        <span>{{statistics.geregistreerden.jawoord}}/{{statistics.genodigden.jawoord}}</span>
      </div>
      <div>
        <app-locatie-icon
          [icon]="'3-receptie.png'">
        </app-locatie-icon>
        <span>{{statistics.geregistreerden.receptie}}/{{statistics.genodigden.receptie}}</span>
      </div>
      <div>
        <app-locatie-icon
          [icon]="'4-diner.png'">
        </app-locatie-icon>
        <span>{{statistics.geregistreerden.diner}}/{{statistics.genodigden.diner}}</span>
      </div>
      <div>
        <app-locatie-icon
          [icon]="'5-dessert.png'">
        </app-locatie-icon>
        <span>{{statistics.geregistreerden.dessert}}/{{statistics.genodigden.dessert}}</span>
      </div>
    </div>

    <h1>Genodigden</h1>

    <div class="table-wrapper">
      <table>
        <thead>
        <tr>
          <th class="clickable" (click)="sort('namen')">Namen</th>
          <th style="width: 100px;"># login</th>
          <th style="width: 200px;" class="clickable" (click)="sort('lastLogin')">Laatste login</th>
          <th style="width: 100px;">Code</th>
          <th style="width: 75px;">Type</th>
          <th style="width: 200px;" class="clickable" (click)="sort('laatsteWijziging')">Laatste wijziging</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let invitee of inviteeArray">
          <td><a [routerLink]="['/admin/invitees/detail', invitee.id]">{{invitee.voornaam1}}<span *ngIf="invitee.voornaam2"> &amp; {{invitee.voornaam2}}</span><span
            *ngIf="!invitee.voornaam2"> {{invitee.achternaam1}}</span></a></td>
          <td>{{invitee.loginCount}}</td>
          <td>{{invitee.lastLogin}}</td>
          <td>{{invitee.code}}</td>
          <td>{{invitee.type}}</td>
          <td>{{invitee.laatsteWijziging}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div></div>
</div>
