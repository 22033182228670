import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AuthGuard} from './_helpers/auth.guard';
import {LoginComponent} from './login/login.component';
import {GetuigeComponent} from './getuige/getuige.component';
import {LogoutComponent} from './logout/logout.component';
import {InviteeNieuwComponent} from './admin/invitees/nieuw/nieuw.component';
import {InviteeOverzichtComponent} from './admin/invitees/overzicht/overzicht.component';
import {UnpluggedComponent} from './unplugged/unplugged.component';
import {PlanningComponent} from './planning/planning.component';
import {RegistratieComponent} from './registratie/registratie.component';
import {InviteeDetailComponent} from './admin/invitees/detail/invitee-detail.component';
import {CoronaComponent} from './corona/corona.component';


const routes: Routes = [
  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: {
      requireAdmin: false
    }
  },
  {
    path: 'unplugged', component: UnpluggedComponent, canActivate: [AuthGuard], data: {
      requireAdmin: false
    }
  },
  {
    path: 'corona', component: CoronaComponent, canActivate: [AuthGuard], data: {
      requireAdmin: false
    }
  },
  {
    path: 'registratie', component: RegistratieComponent, canActivate: [AuthGuard], data: {
      requireAdmin: false
    }
  },
  {
    path: 'planning', component: PlanningComponent, canActivate: [AuthGuard], data: {
      requireAdmin: false
    }
  },
  {
    path: 'admin', redirectTo: 'admin/invitees/overzicht', canActivate: [AuthGuard], data: {
      requireAdmin: true
    }
  },
  {
    path: 'admin/invitees/overzicht', component: InviteeOverzichtComponent, canActivate: [AuthGuard], data: {
      requireAdmin: true
    }
  },
  {
    path: 'admin/invitees/detail/:id', component: InviteeDetailComponent, canActivate: [AuthGuard], data: {
      requireAdmin: true
    }
  },
  {
    path: 'admin/invitees/nieuw', component: InviteeNieuwComponent, canActivate: [AuthGuard], data: {
      requireAdmin: true
    }
  },
  {
    path: 'vraag', component: GetuigeComponent, canActivate: [AuthGuard], data: {
      requireAdmin: false,
      getuigeVraag: true
    }
  },
  {path: 'logout', component: LogoutComponent},
  {path: 'login', component: LoginComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: '**', redirectTo: '/home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
