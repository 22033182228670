import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LoginResponse} from '../_models/loginresponse';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  public currentUser: Observable<LoginResponse>;
  private currentUserSubject: BehaviorSubject<LoginResponse>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<LoginResponse>(JSON.parse(localStorage.getItem('loginResponse')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get loginResponseValue(): LoginResponse {
    return this.currentUserSubject.value;
  }

  login(code: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>('https://www.rensenlaure.be/api/login.php', {code: code})
      .pipe(map(response => {

        if (!response.succes) {
          return response;
        }

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('loginResponse', JSON.stringify(response));
        this.currentUserSubject.next(response);
        return response;
      }));
  }

  logout() {
    localStorage.removeItem('loginResponse');
    this.currentUserSubject.next(null);
  }

}
