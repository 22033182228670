<div class="vlekken-op-achtergrond">
  <div class="page-wrapper">
    <div class="header">
      <button class="button-small no-select" routerLink="/admin/invitees/overzicht">Terug</button>
      <button class="button-small no-select" routerLink="/logout">Afmelden</button>
    </div>

    <h1>{{namen}}</h1>

    <div>
      <div>
        <span>{{invitee.type == 'AVOND' ? 'Avonduitnodiging' : 'Daguitnodiging'}}</span>
      </div>
      <div>
        <app-locatie-icon
          [icon]="'1-firstlook.png'">
        </app-locatie-icon>
        <span><b>First look</b></span><br/>
        <span>{{invitee.voornaam1}}: {{invitee.firstLook1 == 1 ? 'Aanwezig' : 'Niet aanwezig'}}</span><br/>
        <span>{{invitee.voornaam2}}: {{invitee.firstLook2 == 1 ? 'Aanwezig' : 'Niet aanwezig'}}</span><br/>
      </div>
      <div>
        <app-locatie-icon
          [icon]="'2-jawoord.png'">
        </app-locatie-icon>
        <span><b>Jawoord</b></span><br/>
        <span>{{invitee.voornaam1}}: {{invitee.jaWoord1 == 1 ? 'Aanwezig' : 'Niet aanwezig'}}</span><br/>
        <span>{{invitee.voornaam2}}: {{invitee.jaWoord2 == 1 ? 'Aanwezig' : 'Niet aanwezig'}}</span><br/>
      </div>
      <div>
        <app-locatie-icon
          [icon]="'3-receptie.png'">
        </app-locatie-icon>
        <span><b>Receptie</b></span><br/>
        <span>{{invitee.voornaam1}}: {{invitee.receptie1 == 1 ? 'Aanwezig' : 'Niet aanwezig'}}</span><br/>
        <span>{{invitee.voornaam2}}: {{invitee.receptie2 == 1 ? 'Aanwezig' : 'Niet aanwezig'}}</span><br/>
      </div>
      <div>
        <app-locatie-icon
          [icon]="'4-diner.png'">
        </app-locatie-icon>
        <span><b>Diner</b></span><br/>
        <span>{{invitee.voornaam1}}: {{invitee.diner1 == 1 ? 'Aanwezig' : 'Niet aanwezig'}}</span><br/>
        <span>{{invitee.voornaam2}}: {{invitee.diner2 == 1 ? 'Aanwezig' : 'Niet aanwezig'}}</span><br/>
      </div>
      <div>
        <app-locatie-icon
          [icon]="'5-dessert.png'">
        </app-locatie-icon>
        <span><b>Dessert</b></span><br/>
        <span>{{invitee.voornaam1}}: {{invitee.dessert1 == 1 ? 'Aanwezig' : 'Niet aanwezig'}}</span><br/>
        <span>{{invitee.voornaam2}}: {{invitee.dessert2 == 1 ? 'Aanwezig' : 'Niet aanwezig'}}</span><br/>
      </div>

      <div>
        <br />
        <span><b>Opmerking:</b></span><br />
        <span>{{invitee.opmerkingen}}</span><br />

        <br />

        <span><b>Email:</b></span><br />
        <span>{{invitee.email}}</span><br />
      </div>
    </div>
  </div>
</div>
