import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../_services/authentication.service';

@Injectable({providedIn: 'root'})
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let loginResponse = this.authenticationService.loginResponseValue;

    if (loginResponse && loginResponse.token) {
      request = request.clone({
        setHeaders: {
          AUTHTOKEN: `${loginResponse.token}`
        }
      });
    }

    return next.handle(request);
  }

}
