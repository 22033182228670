import {Component, OnInit} from '@angular/core';
import {LoginResponse} from '../_models/loginresponse';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthenticationService} from '../_services/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-getuige',
  templateUrl: './getuige.component.html',
  styleUrls: ['./getuige.component.scss']
})
export class GetuigeComponent implements OnInit {

  public getuigeForm: FormGroup;
  naam: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.getuigeForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    const loginResponse = JSON.parse(localStorage.getItem('loginResponse')) as LoginResponse;

    this.naam = loginResponse.getuige;
  }

  onSubmit(): void {
  }

  deelEenJa(): void {
    document.querySelector('.een').classList.toggle('hidden');
    document.querySelector('.twee').classList.toggle('hidden');
  }

  deelTweeJa(): void {
    document.querySelector('.twee').classList.toggle('hidden');
    document.querySelector('.drie').classList.toggle('hidden');
  }

  deelDrieJa(): void {
    document.querySelector('.drie').classList.toggle('hidden');
    document.querySelector('.vier').classList.toggle('hidden');
  }

  nee(): void {
    document.querySelectorAll('.vraag-deel').forEach(item => {
      item.classList.remove('hidden');
    });

    document.querySelector('.twee').classList.toggle('hidden');
    document.querySelector('.drie').classList.toggle('hidden');
    document.querySelector('.vier').classList.toggle('hidden');
  }

  afmelden(): void {
    this.authenticationService.logout();
    this.router.navigate(['/']);
  }

}
