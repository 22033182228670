import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../_services/authentication.service';
import {Subscription} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-corona',
  templateUrl: './corona.component.html',
  styleUrls: ['./corona.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate('1s 10s')
      ])
    ])
  ]
})
export class CoronaComponent implements OnInit, OnDestroy {

  enkel: boolean;

  currentUserSubscription: Subscription;

  constructor(
    private authService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.currentUserSubscription = this.authService.currentUser.subscribe(data => {
      this.enkel = data.user.voornaam2 === '';
    });
  }

  ngOnDestroy(): void {
    if (this.currentUserSubscription !== undefined) {
      this.currentUserSubscription.unsubscribe();
    }
  }

}
