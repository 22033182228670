import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenTestService {

  constructor(private http: HttpClient) {
  }

  testToken(): Observable<string> {
    return this.http.get<string>('https://www.rensenlaure.be/api/token-test.php');
  }
}
